<script lang="ts" setup>
import type { media } from '~/interfaces/blocks.interface'

defineProps<{
  content: media
}>()

const viewVideo = ref(false)
</script>

<template>
  <section>
    <UContainer class="media block first:mt-100" data-test="block">
      <div v-if="content.url || content.file.data">
        <div
          v-if="
            content.file.data && content.file.data.attributes.ext === '.pdf'
          "
        >
          <UButton color="primaryLight" icon="i-fa6-solid-download">{{
            content.file.data.attributes.name
          }}</UButton>
        </div>
        <template v-else>
          <div v-if="!content.url" class="flex justify-center">
            <AkImage
              class="mx-auto rounded-16"
              :image="content.file"
              :legend-position="content.legend_position"
            />
          </div>
          <div v-else-if="content.url">
            <AkRow justify="center">
              <AkCol :cols="12" :cols-lg="10">
                <div
                  v-if="(content.file.data || content.url) && !viewVideo"
                  class="focus relative duration-300"
                  :class="{ 'cursor-pointer': content.url }"
                  @click="content.url ? (viewVideo = true) : ''"
                >
                  <div class="flex justify-center">
                    <div class="relative">
                      <div
                        v-if="content.url && !viewVideo"
                        class="absolute bottom-0 left-0 right-0 top-0 rounded-16 bg-black/60"
                      ></div>
                      <div
                        class="absolute bottom-30 left-20 right-20 lg:bottom-50 lg:left-40"
                      >
                        <p
                          class="font-montserrat text-12 font-semibold italic leading-22 text-blue-200 max-lg:max-w-[90%] lg:max-w-[330px] lg:text-24 lg:leading-36 lg:tracking-[-0.48px]"
                        >
                          <span class="text-secondary-light">“</span>&nbsp;{{
                            content.text
                          }}&nbsp;<span class="text-secondary-light">”</span>
                        </p>
                        <p
                          class="lg:mt-25 mt-12 text-p4 text-blue-200 lg:max-w-[330px] lg:text-p1"
                        >
                          {{ content.author }}
                        </p>
                        <div
                          class="lg:mt-25 mt-12 h-2 w-66 bg-secondary-light lg:w-126"
                        ></div>
                      </div>
                      <AkImage
                        v-if="content.file.data"
                        align="center"
                        class="mx-auto max-h-[570px] w-full rounded-16 object-cover lg:aspect-[16/9] lg:max-h-[490px]"
                        :image="content.file"
                        :image-mobile="content.image_mobile"
                        no-twic-size="1200"
                        no-twic-size-mobile="800"
                      />
                      <div
                        v-else-if="content.url && !viewVideo"
                        class="bg-primary aspect-video w-full"
                      />
                      <div
                        v-if="content.url"
                        class="bg-primary group absolute right-10 top-10 flex h-56 w-56 items-center justify-center rounded-full transition duration-300 hover:bg-white lg:right-20 lg:top-20"
                      >
                        <UIcon
                          class="group-hover:text-primary h-24 w-24 text-white transition duration-300"
                          name="i-fa6-solid-play"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <iframe
                  v-show="viewVideo"
                  ref="vimeoVideo"
                  class="block aspect-video w-full"
                  :src="
                    viewVideo
                      ? `${content.url
                          .replace('youtu.be', 'youtube.com/embed')
                          .replace('/watch/v=', 'embed/')
                          .replace(
                            'https://vimeo.com',
                            'https://player.vimeo.com/video'
                          )}${content.url.indexOf('?') !== -1 ? '&autoplay=1&muted=1' : '?autoplay=1&muted=1'}`
                      : ''
                  "
                />
              </AkCol>
            </AkRow>
          </div>
        </template>
      </div>
    </UContainer>
  </section>
</template>

<style lang="scss" scoped>
.media {
  url {
    @apply w-full;
    aspect-ratio: 16 / 9;
  }
}
</style>
